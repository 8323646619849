import React, { useState, useEffect, useContext } from 'react';
import { DateTime } from 'luxon';
import { DatePickerModal } from 'react-rainbow-components';
import { AuthContext } from '../../AuthContext'
import './Calendar.css';

const CalendarDropdown = (props) => {

  const { currentUser } = useContext(AuthContext);

  const [isOpen, setIsOpen] = useState(false);
  const [date, setDate] = useState(props.selectedDate); // Initialize with the selected date

  useEffect(() => {
    setDate(props.selectedDate)
  }, [props.selectedDate]);

  const handleDateChange = (date) => {
    setDate(date); // Set date for local component state
    props.setSelectedDate(date); // Pass the selected date to parent via props
    props.dateSelected(date); // Trigger any additional callback
    setIsOpen(false); // Close the calendar
  };

  const toggleCalendar = () => {

    if (!currentUser) {
      window.location.href = "/signin";
      return
    }

    setIsOpen(true);
  };

  // Helper function to calculate the difference in days using Luxon
  const differenceInDays = (date1, date2) => {
    const startOfDay1 = DateTime.fromJSDate(date1).startOf('day');
    const startOfDay2 = DateTime.fromJSDate(date2).startOf('day');
    return startOfDay1.diff(startOfDay2, 'days').days;
  };

  // Calculate the difference in days between the selected date and today
  const difference = differenceInDays(props.selectedDate, new Date());
  let dateText = '';

  if (difference === 0) {
    dateText = 'Today';
  } else if (difference === 1) {
    dateText = 'Tomorrow';
  } else if (difference === -1) {
    dateText = 'Yesterday';
  } else if (difference > 1) {
    dateText = `In ${difference} Days`;
  } else {
    dateText = `${Math.abs(difference)} Days Ago`;
  }

  return (
    <div className="calendar-dropdown">
      {/* Dropdown Trigger */}
      <div onClick={toggleCalendar} className="flex items-center cursor-pointer">
        <p className="text-gray-500 font-medium text-sm mr-3">{dateText}</p>
        <div className="date-display flex px-3 py-1 text-sm font-medium text-myteal-500 border border-gray-300 rounded cursor-pointer">
          {DateTime.fromJSDate(props.selectedDate).toLocaleString(DateTime.DATE_SHORT)}
          {isOpen ? 
            <svg xmlns="http://www.w3.org/2000/svg" className="text-myteal-500 ml-3 mt-1" height="14" width="14" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M112 328l144-144 144 144"/></svg>
          :
            <svg xmlns="http://www.w3.org/2000/svg" className="text-myteal-500 ml-3 mt-1" height="14" width="14" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M112 184l144 144 144-144"/></svg>
          }
        </div>
      </div>

      {/* DatePickerModal Component */}
      {isOpen && (
        <DatePickerModal
          isOpen={isOpen}
          value={date}
          onRequestClose={() => setIsOpen(false)}
          onChange={handleDateChange}
          formatStyle="large"
          borderRadius="semi-square"
        />
      )}
    </div>
  );
};

export default CalendarDropdown;



/* 
   DROPDOWN CALENDER
*/

// import React, { useState } from 'react';
// import { DateTime } from 'luxon';
// import { DayPicker } from 'react-day-picker';
// import './Calendar.css';

// const CalendarDropdown = (props) => {
//   const [isOpen, setIsOpen] = useState(false);

//   const handleDateChange = (date) => {
//     props.setSelectedDate(date);
//     props.dateSelected(date);
//     setIsOpen(false); // Close calendar on date selection
//   };

//   const toggleCalendar = () => {
//     setIsOpen(!isOpen);
//   };

//   // Helper function to calculate the difference in days using Luxon
//   const differenceInDays = (date1, date2) => {
//     const startOfDay1 = DateTime.fromJSDate(date1).startOf('day');
//     const startOfDay2 = DateTime.fromJSDate(date2).startOf('day');
//     return startOfDay1.diff(startOfDay2, 'days').days;
//   };

//   // Calculate the difference in days between the selected date and today
//   const difference = differenceInDays(props.selectedDate, new Date());
//   let dateText = '';

//   if (difference === 0) {
//     dateText = 'Today';
//   } else if (difference === 1) {
//     dateText = 'Tomorrow';
//   } else if (difference === -1) {
//     dateText = 'Yesterday';
//   } else if (difference > 1) {
//     dateText = `In ${difference} Days`;
//   } else {
//     dateText = `${Math.abs(difference)} Days Ago`;
//   }

//   return (
//     <div className="calendar-dropdown">
//       {/* Dropdown Trigger */}
//       <div onClick={toggleCalendar} className="flex items-center cursor-pointer">
//         <p className="text-gray-500 font-medium text-sm mr-3">{dateText}</p>
//         <div className="date-display flex px-3 py-1 text-sm font-medium text-myteal-500 border border-gray-300 rounded cursor-pointer">
//           {DateTime.fromJSDate(props.selectedDate).toLocaleString(DateTime.DATE_SHORT)}
//           {isOpen ? 
//             <svg xmlns="http://www.w3.org/2000/svg" className="text-myteal-500 ml-3 mt-1" height="14" width="14" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M112 328l144-144 144 144"/></svg>
//           :
//             <svg xmlns="http://www.w3.org/2000/svg" className="text-myteal-500 ml-3 mt-1" height="14" width="14" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M112 184l144 144 144-144"/></svg>
//           }
//         </div>
//       </div>

//       {/* Calendar Component */}
//       {isOpen && (
//         <div className="calendar-popup absolute z-10 mt-2 bg-white shadow-xl rounded-lg">
//           <DayPicker
//             mode="single"
//             selected={props.selectedDate}
//             onSelect={handleDateChange}
//           />
//         </div>
//       )}
//     </div>
//   );
// };

// export default CalendarDropdown;


