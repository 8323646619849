import './MapInterface.css'
import React, { useState, useEffect, useRef } from "react";
import {
  useLoadScript,
  GoogleMap,
  Marker,
  InfoWindow,
  DirectionsRenderer
} from "@react-google-maps/api";
import mapStyles from "./mapStyles"; // checkout snazzymaps.com
import e from 'cors';

function Map(props) {
    const [markers, setMarkers] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [directions, setDirections] = useState(null);

    const [mapInstance, setMapInstance] = useState(null);
    const allRenderers = useRef([]);
    const allMarkers = useRef([]);

  
    useEffect(() => {

        // first of all if labeled and ordered markers from post-optimization are still there, clear
        if (allMarkers.current.length > 0 || allRenderers.current.length > 0) {
            // Clear existing renderers and markers
            allRenderers.current.forEach(renderer => renderer.setMap(null));
            allMarkers.current.forEach(marker => marker.setMap(null));
            allRenderers.current = [];
            allMarkers.current = [];
        }
    
      // Generate markerData from stopList and addressCoordinateList
      const markerData = props.stopList.map(name => {
        const loc = props.addressCoordinateList.stops.find(s => s.name === name);
        return loc ? { ...loc, type: 'stop' } : null;
      }).filter(Boolean);
  
      // Add start and end markers
      if (props.addressCoordinateList.start.name) {
        markerData.unshift({ ...props.addressCoordinateList.start, type: 'start' });
      }
      if (props.addressCoordinateList.end.name) {
        markerData.push({ ...props.addressCoordinateList.end, type: 'end' });
      }

      // SVG Icon for markers
      const svgIcon = {
        path: "M 0,0 C -4.5,-13.5 -13.5,-15.3 -13.5,-24.3 A 13.5,13.5 0 1,1 13.5,-24.3 C 13.5,-15.3 4.5,-13.5 0,0 z",
        fillColor: '#00be95',
        fillOpacity: 1,
        strokeColor: '#00a380',
        strokeWeight: 2,
        scale: 1,
        anchor: new window.google.maps.Point(0, 0),
        labelOrigin: new window.google.maps.Point(0, -23)
      };
  
      // Convert markerData to JSX markers
      const jsxMarkers = markerData.map((loc, index) => {
        let markerLabel;
        if (loc.type === 'stop') {
          markerLabel = {
            text: String(index),
            color: 'white',
            fontWeight: 'bold',
            fontSize: '15px'
          };
        } else if (loc.type === 'end' && props.addressCoordinateList.start.name !== props.addressCoordinateList.end.name) {
            markerLabel = {
                text: '⚑',
                color: 'white',
                fontWeight: 'bold',
                fontSize: '15px'
              };
        } else {
            markerLabel = {
                text: '◉',
                color: 'white',
                fontWeight: 'bold',
                fontSize: '15px'
              };
        }
      
        return {
          ...loc,
          mark: (
            <Marker
              key={index}
              visible={true}
              position={{ lat: loc.coordinates[0], lng: loc.coordinates[1] }}
              onClick={() => setSelectedLocation(loc)}
              label={markerLabel} // This will only set the label for 'stop' markers.
              icon={svgIcon}
            />
          )
        };
      });
      
      setMarkers(jsxMarkers);
  
    }, [props.stopList, props.addressCoordinateList]);


    
    let mapCenter
    let mapZoom

    if (markers.length > 0) {
        if (markers.length > 1 && markers[markers.length-1].type === 'end') {
            mapCenter = { lat: markers[markers.length-2].coordinates[0], lng: markers[markers.length-2].coordinates[1] }
        } else {
            mapCenter = { lat: markers[markers.length-1].coordinates[0], lng: markers[markers.length-1].coordinates[1] }
        }
        mapZoom = 10
    } else {
        mapZoom = 4
        if (props.addressCoordinateList.start.name === '' && props.addressCoordinateList.end.name === '' && props.addressCoordinateList.stops.length == 0) {
          mapCenter = { lat: 37.0902, lng: -95.7129}
        }
    }
  
  /* 
    post optimization
  */

// icon design
const coloredIcon = (label) => {
    const SVG_PIN = `
        M 0,0 C -4.5,-13.5 -13.5,-15.3 -13.5,-24.3 A 13.5,13.5 0 1,1 13.5,-24.3 C 13.5,-15.3 4.5,-13.5 0,0 z
    `;


    return {
        path: SVG_PIN,
        fillColor: '#00be95',
        fillOpacity: 1,
        strokeColor: '#00a380',
        strokeWeight: 2,
        scale: 1,
        anchor: new window.google.maps.Point(0, 0),
        labelOrigin: new window.google.maps.Point(0, -23)
    };
    
}

useEffect(() => {
    if (props.fullAddressList.length > 0) {
        console.log('NEW ROUTE ORDER', props.newRouteOrder);
        console.log('ADDRESS COORDINATE LIST', props.addressCoordinateList);

        setMarkers([]);

        const directionsService = new window.google.maps.DirectionsService();

        // Clear existing renderers and markers
        allRenderers.current.forEach(renderer => renderer.setMap(null));
        allMarkers.current.forEach(marker => marker.setMap(null));
        allRenderers.current = [];
        allMarkers.current = [];

        let orderedArray = [];
        for (let a = 0; a < props.newRouteOrder.length; a++) {
            orderedArray.push({label: (a).toString(), address: props.newRouteOrder[a].address, coordinates: {lat: null, lng: null}})
        }

        // setting up coordinates with the ordered array using the address coordinate list 
        for (let a = 0; a < orderedArray.length; a++) {
            for (let b = 0; b < props.addressCoordinateList.stops.length; b++) {
                let orderedArrayAddress = orderedArray[a].address // from API
                let originalAddress = props.addressCoordinateList.stops[b].name // from original entries
                if (orderedArrayAddress === originalAddress) {
                    orderedArray[a].coordinates = {lat: props.addressCoordinateList.stops[b].coordinates[0], lng: props.addressCoordinateList.stops[b].coordinates[1]};
                    break
                }
            }
        }

        // if start and end address are same keep same symbol, else mark end as flag
        orderedArray[0] = {label: '◉', address: orderedArray[0].address, coordinates: {lat: props.addressCoordinateList.start.coordinates[0], lng: props.addressCoordinateList.start.coordinates[1]}}
        if (orderedArray[0].address === orderedArray[orderedArray.length-1].address) {
            orderedArray[orderedArray.length-1] = {label: '◉', address: orderedArray[orderedArray.length-1].address, coordinates: {lat: props.addressCoordinateList.end.coordinates[0], lng: props.addressCoordinateList.end.coordinates[1]}}
        } else { 
            orderedArray[orderedArray.length-1] = {label: '⚑', address: orderedArray[orderedArray.length-1].address, coordinates: {lat: props.addressCoordinateList.end.coordinates[0], lng: props.addressCoordinateList.end.coordinates[1]}}
        }
        console.log('ORDERED ARRAY WITH COORDINATES', orderedArray)

        let currTravelMode = window.google.maps.TravelMode.DRIVING;
        if (props.mode === 'b') {
            currTravelMode = window.google.maps.TravelMode.BICYCLING;
        } else if (props.mode === 'w') {
            currTravelMode = window.google.maps.TravelMode.WALKING;
        }

        // Define a recursive function to fetch and render segmented routes.
        const fetchSegmentedRoutes = (startIndex = 0) => {
            if (startIndex >= orderedArray.length - 1) {
                console.log('All segments processed.');
                return;
            }
        
            const endIndex = Math.min(startIndex + 26, orderedArray.length - 1);
            const origin = orderedArray[startIndex].coordinates;
            const destination = orderedArray[endIndex].coordinates;
            const waypoints = orderedArray.slice(startIndex + 1, endIndex).map(point => ({
                location: point.coordinates,
                stopover: true
            }));
        
            directionsService.route({
                origin: origin,
                destination: destination,
                travelMode: currTravelMode,
                waypoints: waypoints
            }, (result, status) => {
                if (status === window.google.maps.DirectionsStatus.OK) {

                    const legMarkers = [];
                    result.routes[0].legs.forEach((leg, index) => {
                        const marker = new window.google.maps.Marker({
                            position: leg.start_location,
                            label: {
                                color: 'white',
                                fontWeight: 'bold',
                                text: orderedArray[startIndex + index].label,
                                fontSize: '15px'
                            },
                            icon: coloredIcon(orderedArray[startIndex + index].label),
                            map: mapInstance
                        });
                        
                        
                        // Create InfoWindow for the marker
                        const infoWindow = new window.google.maps.InfoWindow({
                            content: `<div>${orderedArray[startIndex + index].address}</div>`
                        });
                        
                        // Add click event listener to the marker
                        marker.addListener('click', () => {
                            infoWindow.open(mapInstance, marker);
                        });
                        
                        legMarkers.push(marker);

                    });

                    const destinationMarker = new window.google.maps.Marker({
                        position: result.routes[0].legs[result.routes[0].legs.length - 1].end_location,
                        label: {
                            color: 'white',
                            fontWeight: 'bold',
                            text: orderedArray[endIndex].label,
                            fontSize: '15px'
                        },
                        zIndex: 1000,
                        icon: coloredIcon(orderedArray[endIndex].label),
                        map: mapInstance
                    });
                    

                    // Create InfoWindow for the destinationMarker
                    const destInfoWindow = new window.google.maps.InfoWindow({
                        content: `<div>${orderedArray[endIndex].address}</div>`
                    });

                    // Add click event listener to the destinationMarker
                    destinationMarker.addListener('click', () => {
                        destInfoWindow.open(mapInstance, destinationMarker);
                    });

                    legMarkers.push(destinationMarker);
    
                    const directionsRenderer = new window.google.maps.DirectionsRenderer({
                        map: mapInstance,
                        suppressMarkers: true, // This is important! It will hide default markers.
                        directions: result
                    });
    
                    // Add the newly created renderers and markers to the useRef collections
                    allRenderers.current.push(directionsRenderer);
                    legMarkers.forEach(marker => allMarkers.current.push(marker));
            
                    fetchSegmentedRoutes(endIndex);
                } else {
                    console.error(`Error fetching directions for segment starting at ${startIndex}: ${status}`);
                    if (status == 'ZERO_RESULTS') {
                      alert('Map preview not available for this route.')
                    }
                }
            });
        }
        

        // Start processing the segments.
        fetchSegmentedRoutes();
    }
}, [props.newRouteOrder, props.mode]);


  return (
    <GoogleMap
      onLoad={map => setMapInstance(map)}
      zoom={mapZoom}
      center={mapCenter}
      options={{ styles: mapStyles }}
      mapContainerStyle={{ width: "100%", height: "100%" }}
    >
      {markers.map(location => (
        location.mark
      ))}
      <DirectionsRenderer
        directions={directions}
      />
      {selectedLocation && (
        <InfoWindow
          onCloseClick={() => {
            setSelectedLocation(null);
          }}
          position={{
            lat: selectedLocation.coordinates[0],
            lng: selectedLocation.coordinates[1]
          }}
        >
          <div>
            <p className="infowindow-text">{selectedLocation.name}</p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
}

function MapInterface(props) {
    const [isGoogleMapsReady, setIsGoogleMapsReady] = useState(false);

    useEffect(() => {
        if (window.google && window.google.maps) {
            // Google Maps API is loaded and ready to use
            setIsGoogleMapsReady(true);
        } else {
            const handleLoad = () => {
                setIsGoogleMapsReady(true);
            };
            window.addEventListener("load", handleLoad);

            return () => {
                window.removeEventListener("load", handleLoad);
            };
        }
    }, []);

    return (
        <div className="map">
            {isGoogleMapsReady && <Map {...props} />}
        </div>
    );
}

export default MapInterface;