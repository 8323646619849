import './MapInterfaceTeams.css'
import React, { useState, useEffect, useRef, useContext } from "react";
import {
  useLoadScript,
  GoogleMap,
  Marker,
  InfoWindow,
  DirectionsRenderer
} from "@react-google-maps/api";
import mapStylesTeams from "./mapStylesTeams"; // checkout snazzymaps.com
import e from 'cors';
import { DateTime } from 'luxon';
import { AuthContext } from '../../AuthContext'


function Map(props) {
    const { currentUser, addTeamsRoutes } = useContext(AuthContext);

    const [markers, setMarkers] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [directions, setDirections] = useState(null);
    const [removeRouteLines, setRemoveRouteLines] = useState(0)

    const [mapInstance, setMapInstance] = useState(null);
    const allRenderers = useRef([]);
    const allMarkers = useRef([]);

    async function optimizeRequest(originRouteOrder, newRouteOrder, originIndex, newIndex, movedStop) {

      props.setOpenLoading(true)

      // Create copies of the route orders
      let updatedOriginRouteOrder = [...originRouteOrder];
      let updatedNewRouteOrder = [...newRouteOrder];
  
      // Remove the moved stop from the origin route
      const movedStopIndex = updatedOriginRouteOrder.findIndex(stop => stop.address === movedStop.address);
      if (movedStopIndex !== -1) {
          updatedOriginRouteOrder.splice(movedStopIndex, 1);  // Remove the moved stop
      } else {
          console.error("Moved stop not found in originRouteOrder");
      }

      // fix coordinates format
      let coords = []
      coords.push(movedStop.coordinates.lat)
      coords.push(movedStop.coordinates.lng)
      movedStop.coordinates = coords
  
      // Add the moved stop to the new route, right before the destination
      const lastStopIndex = updatedNewRouteOrder.length - 1;
      updatedNewRouteOrder.splice(lastStopIndex, 0, movedStop);
  
      // Optimize both routes
      try {
          // Optimize origin route
          const originStopList = updatedOriginRouteOrder.slice(1, updatedOriginRouteOrder.length - 1).map(stop => ({
              address: stop.address,
              coordinates: stop.coordinates
          }));
  
          const originParameters = {
              startAddress: updatedOriginRouteOrder[0],
              stopList: originStopList,
              destinationAddress: updatedOriginRouteOrder[updatedOriginRouteOrder.length - 1]
          };
  
          const optimizedOriginRoute = await props.optimizeRoute(originParameters);
  
          // Optimize new route
          const newStopList = updatedNewRouteOrder.slice(1, updatedNewRouteOrder.length - 1).map(stop => ({
              address: stop.address,
              coordinates: stop.coordinates
          }));
  
          const newParameters = {
              startAddress: updatedNewRouteOrder[0],
              stopList: newStopList,
              destinationAddress: updatedNewRouteOrder[updatedNewRouteOrder.length - 1]
          };
  
          const optimizedNewRoute = await props.optimizeRoute(newParameters);
  
          // Update driverRoutes with the optimized routes
          const updatedDriverRoutes = props.driverRoutes.routes.map((route, index) => {
              if (index === originIndex) {
                  // Update the origin route
                  return {
                      ...route,
                      routeOrder: optimizedOriginRoute.routeOrder,
                      curatedLinks: optimizedOriginRoute.curatedLinks,
                      routoraLink: optimizedOriginRoute.routoraLink,
                      splitUrls: optimizedOriginRoute.splitUrls,
                      totalDistance: optimizedOriginRoute.totalDistance,
                      totalTime: optimizedOriginRoute.totalTime
                  };
              } else if (index === newIndex) {
                  // Update the new route
                  return {
                      ...route,
                      routeOrder: optimizedNewRoute.routeOrder,
                      curatedLinks: optimizedNewRoute.curatedLinks,
                      routoraLink: optimizedNewRoute.routoraLink,
                      splitUrls: optimizedNewRoute.splitUrls,
                      totalDistance: optimizedNewRoute.totalDistance,
                      totalTime: optimizedNewRoute.totalTime
                  };
              } else {
                  // Keep the route unchanged
                  return route;
              }
          });
  
          // Update the driver routes state with the new optimized routes
          let newDriverRoutes = {
            routes: updatedDriverRoutes
          }
          props.setOpenLoading(false)
          props.setDriverRoutes(newDriverRoutes)

          const formattedDate = DateTime.fromJSDate(props.selectedDate).toFormat('yyyy-MM-dd');
          addTeamsRoutes(currentUser.email, formattedDate, newDriverRoutes)
  
      } catch (error) {
          console.error('Error during optimization:', error);
          props.setOpenLoading(false)
      }
    }
  
  


    useEffect(() => {

        // first of all if labeled and ordered markers from post-optimization are still there, clear
        if (allMarkers.current.length > 0 || allRenderers.current.length > 0) {
            // Clear existing renderers and markers
            allRenderers.current.forEach(renderer => renderer.setMap(null));
            allMarkers.current.forEach(marker => marker.setMap(null));
            allRenderers.current = [];
            allMarkers.current = [];
        }
    
      // Generate markerData from stopList and addressCoordinateList
      const markerData = props.stopList.map(name => {
        const loc = props.addressCoordinateList.stops.find(s => s.name === name);
        return loc ? { ...loc, type: 'stop' } : null;
      }).filter(Boolean);
  
      // Add start and end markers
      if (props.addressCoordinateList.start.name) {
        markerData.unshift({ ...props.addressCoordinateList.start, type: 'start' });
      }
      if (props.addressCoordinateList.end.name) {
        markerData.push({ ...props.addressCoordinateList.end, type: 'end' });
      }
      // console.log('props addressCoordinateList', props.addressCoordinateList)
      // console.log('markerData', markerData)

      // SVG Icon for markers
      const svgIcon = {
        path: "M 0,0 C -4.5,-13.5 -13.5,-15.3 -13.5,-24.3 A 13.5,13.5 0 1,1 13.5,-24.3 C 13.5,-15.3 4.5,-13.5 0,0 z",
        fillColor: '#00be95',
        fillOpacity: 1,
        strokeColor: '#00a380',
        strokeWeight: 2,
        scale: 0.9,
        anchor: new window.google.maps.Point(0, 0),
        labelOrigin: new window.google.maps.Point(0, -23)
      };
  
      // Convert markerData to JSX markers
      const jsxMarkers = markerData.map((loc, index) => {
        let markerLabel;
        if (loc.type === 'stop') {
          markerLabel = {
            text: String(index),
            color: 'white',
            fontWeight: 'bold',
            fontSize: '13px'
          };
        } else if (loc.type === 'end' && props.addressCoordinateList.start.name !== props.addressCoordinateList.end.name) {
            markerLabel = {
                text: '⚑',
                color: 'white',
                fontWeight: 'bold',
                fontSize: '13px'
              };
        } else {
            markerLabel = {
                text: '◉',
                color: 'white',
                fontWeight: 'bold',
                fontSize: '13px'
              };
        }
      
        return {
          ...loc,
          mark: (
            <Marker
              key={index}
              visible={true}
              position={{ lat: loc.coordinates[0], lng: loc.coordinates[1] }}
              onClick={() => setSelectedLocation(loc)}
              label={markerLabel} // This will only set the label for 'stop' markers.
              icon={svgIcon}
            />
          )
        };
      });
      
      setMarkers(jsxMarkers);
  
    }, [props.stopList, props.addressCoordinateList, removeRouteLines]);


    
    let mapCenter
    let mapZoom

    if (markers.length > 0) {
        if (markers.length > 1 && markers[markers.length-1].type === 'end') {
            mapCenter = { lat: markers[markers.length-2].coordinates[0], lng: markers[markers.length-2].coordinates[1] }
        } else {
            mapCenter = { lat: markers[markers.length-1].coordinates[0], lng: markers[markers.length-1].coordinates[1] }
        }
        mapZoom = 10
    } else {
        mapZoom = 4
        if (props.addressCoordinateList.start.name === '' && props.addressCoordinateList.end.name === '' && props.addressCoordinateList.stops.length == 0) {
          mapCenter = { lat: 37.0902, lng: -95.7129}
        }
    }
  
/* 
    post optimization
  */

// icon design
const coloredIcon = (label, color) => {
  const SVG_PIN = `
      M 0,0 C -4.5,-13.5 -13.5,-15.3 -13.5,-24.3 A 13.5,13.5 0 1,1 13.5,-24.3 C 13.5,-15.3 4.5,-13.5 0,0 z
  `;

  // Function to darken the color
  const darkenColor = (color, amount) => {
    let usePound = false;
    if (color[0] === "#") {
      color = color.slice(1);
      usePound = true;
    }

    let num = parseInt(color, 16);
    let r = (num >> 16) + amount;
    let g = ((num >> 8) & 0x00ff) + amount;
    let b = (num & 0x0000ff) + amount;

    r = r > 255 ? 255 : r < 0 ? 0 : r;
    g = g > 255 ? 255 : g < 0 ? 0 : g;
    b = b > 255 ? 255 : b < 0 ? 0 : b;

    return (usePound ? "#" : "") + (r << 16 | g << 8 | b).toString(16).padStart(6, "0");
  };

  // Get a slightly darker version of the given color
  const darkerColor = darkenColor(color, -30); // You can adjust the -30 value to darken more or less

  return {
    path: SVG_PIN,
    fillColor: color,
    fillOpacity: 1,
    strokeColor: darkerColor, // Use the darker version of the color for the border
    strokeWeight: 2,          // Increase the weight to make the border more visible
    scale: 0.9,
    anchor: new window.google.maps.Point(0, 0),
    labelOrigin: new window.google.maps.Point(0, -23)
  };
};


useEffect(() => {
  if (props.driverRoutes) {

      setMarkers([]);

      const directionsService = new window.google.maps.DirectionsService();

      // Clear existing renderers and markers
      allRenderers.current.forEach(renderer => renderer.setMap(null));
      allMarkers.current.forEach(marker => marker.setMap(null));
      allRenderers.current = [];
      allMarkers.current = [];

      // Loop through each route
      props.driverRoutes.routes.forEach((route, routeIndex) => {
          
          // Check if this is the route for the selected driver
          const isSelectedRoute = props.clickedDriver === 0 || route.driverInfo.id === props.clickedDriver;
          
          let orderedArray = [];
          for (let a = 0; a < route.routeOrder.length; a++) {
              orderedArray.push({
                  label: (a).toString(),
                  address: route.routeOrder[a].address,
                  coordinates: {
                      lat: route.routeOrder[a].coordinates[0],
                      lng: route.routeOrder[a].coordinates[1]
                  },
                  soloUrls: route.routeOrder[a].soloUrls
              });
          }

          // If start and end address are same keep same symbol, else mark end as flag
          orderedArray[0] = {
              label: '◉',
              address: orderedArray[0].address,
              coordinates: {
                  lat: orderedArray[0].coordinates.lat,
                  lng: orderedArray[0].coordinates.lng
              },
              soloUrls: route.routeOrder[0].soloUrls
          };
          if (orderedArray[0].address === orderedArray[orderedArray.length - 1].address) {
              orderedArray[orderedArray.length - 1] = {
                  label: '◉',
                  address: orderedArray[orderedArray.length - 1].address,
                  coordinates: {
                      lat: orderedArray[orderedArray.length - 1].coordinates.lat,
                      lng: orderedArray[orderedArray.length - 1].coordinates.lng
                  },
                  soloUrls: route.routeOrder[orderedArray.length - 1].soloUrls
              };
          } else {
              orderedArray[orderedArray.length - 1] = {
                  label: '⚑',
                  address: orderedArray[orderedArray.length - 1].address,
                  coordinates: {
                      lat: orderedArray[orderedArray.length - 1].coordinates.lat,
                      lng: orderedArray[orderedArray.length - 1].coordinates.lng
                  },
                  soloUrls: route.routeOrder[orderedArray.length - 1].soloUrls
              };
          }

          let currTravelMode = window.google.maps.TravelMode.DRIVING;
          if (props.mode === 'b') {
              currTravelMode = window.google.maps.TravelMode.BICYCLING;
          } else if (props.mode === 'w') {
              currTravelMode = window.google.maps.TravelMode.WALKING;
          }

          // Define a recursive function to fetch and render segmented routes.
          const fetchSegmentedRoutes = (startIndex = 0) => {
              if (startIndex >= orderedArray.length - 1) {
                  console.log('All segments processed.');
                  return;
              }

              const endIndex = Math.min(startIndex + 26, orderedArray.length - 1);
              const origin = orderedArray[startIndex].coordinates;
              const destination = orderedArray[endIndex].coordinates;
              const waypoints = orderedArray.slice(startIndex + 1, endIndex).map(point => ({
                  location: point.coordinates,
                  stopover: true
              }));

              directionsService.route({
                  origin: origin,
                  destination: destination,
                  travelMode: currTravelMode,
                  waypoints: waypoints
              }, (result, status) => {
                  if (status === window.google.maps.DirectionsStatus.OK) {

                      const legMarkers = [];
                      result.routes[0].legs.forEach((leg, index) => {
                          const marker = new window.google.maps.Marker({
                              position: leg.start_location,
                              label: {
                                  color: 'white',
                                  fontWeight: 'bold',
                                  text: orderedArray[startIndex + index].label,
                                  fontSize: '13px'
                              },
                              icon: coloredIcon(orderedArray[startIndex + index].label, route.driverInfo.colorCode),
                              map: mapInstance,
                              opacity: isSelectedRoute ? 1.0 : 0.2
                          });

                          
                          
                          // Get the current driver for this stop
                          const currentDriverId = route.driverInfo.id;
                          // get current route order
                          const currentRouteOrder = route.routeOrder
                          // get current stop
                          const currentStop = orderedArray[startIndex + index]
                          // Filter out the current driver from the list of drivers
                          const availableDrivers = props.driverRoutes.routes.filter(route => route.driverInfo.id !== currentDriverId);

                          // Create InfoWindow for the marker
                          const infoWindow = new window.google.maps.InfoWindow({
                            content: 
                            `
                            <div class="address-popup-container">
                              <p class="address-popup">${currentStop.address}</p>
                              <div class="buttons-container">
                                <div id="moveButton-${startIndex}" class="move-button" style="cursor: pointer;">
                                  Move
                                </div>
                                <div id="goButton-${startIndex}" class="go-button" style="cursor: pointer;">
                                  Go
                                </div>
                              </div>
                              <div id="driverList-${startIndex}" style="display: none;">
                                <hr class="separator-line">
                                <p class="move-to-text">MOVE TO</p>
                                ${availableDrivers.map((route, driverIndex) => `
                                  <div id="driver-${startIndex}-${driverIndex}" class="driver-button" style="cursor: pointer; background-color: ${route.driverInfo.colorCode};">
                                    ${route.driverInfo.name}
                                  </div>
                                `).join('')}
                              </div>
                            </div>
                            `
                          });

                          // After the InfoWindow opens, attach the event listeners
                          infoWindow.addListener('domready', () => {
                            // Select the "Move" button and "Go" button inside the InfoWindow by their IDs
                            const moveButton = document.getElementById(`moveButton-${startIndex}`);
                            const goButton = document.getElementById(`goButton-${startIndex}`);
                            const driverList = document.getElementById(`driverList-${startIndex}`);

                            // Event listener for the "Move" button
                            if (moveButton) {
                              moveButton.addEventListener('click', () => {
                                console.log('Move button clicked!');
                                driverList.style.display = 'block';  // Show the driver list
                              });
                            }

                            // Event listener for the "Go" button
                            if (goButton) {
                              goButton.addEventListener('click', () => {
                                const googleUrl = orderedArray[startIndex + index].soloUrls.googleUrl;
                                if (googleUrl) {
                                  window.open(googleUrl, '_blank');  // Open the Google Maps route in a new tab
                                }
                              });
                            }

                            // Add click handlers for each driver
                            availableDrivers.forEach((availableRoute, driverIndex) => {
                              const driverElement = document.getElementById(`driver-${startIndex}-${driverIndex}`);
                              
                              if (driverElement) {
                                driverElement.addEventListener('click', () => {
                                  console.log(`Driver ${availableRoute.driverInfo.name} clicked!`);

                                  let currentStopData = {
                                    address: currentStop.address,
                                    coordinates: currentStop.coordinates,
                                    soloUrls: currentStop.soloUrls
                                  };
                                  
                                  // Find the `routeIndex` of the selected driver in `props.driverRoutes.routes`
                                  const targetRouteIndex = props.driverRoutes.routes.findIndex(
                                    route => route.driverInfo.id === availableRoute.driverInfo.id
                                  );
                                  
                                  // Pass the original `routeIndex` and the `targetRouteIndex` to optimizeRequest
                                  optimizeRequest(currentRouteOrder, availableRoute.routeOrder, routeIndex, targetRouteIndex, currentStopData);
                                });
                              }
                            });

                          });

                          // Add click event listener to the marker
                          marker.addListener('click', () => {
                            infoWindow.open(mapInstance, marker);
                          });







                          legMarkers.push(marker);
                      });

                      const destinationMarker = new window.google.maps.Marker({
                          position: result.routes[0].legs[result.routes[0].legs.length - 1].end_location,
                          label: {
                              color: 'white',
                              fontWeight: 'bold',
                              text: orderedArray[endIndex].label,
                              fontSize: '13px'
                          },
                          zIndex: 1000,
                          icon: {
                            path: "M 0,0 C -4.5,-13.5 -13.5,-15.3 -13.5,-24.3 A 13.5,13.5 0 1,1 13.5,-24.3 C 13.5,-15.3 4.5,-13.5 0,0 z",
                            fillColor: '#00be95',
                            fillOpacity: 1,
                            strokeColor: '#00a380',
                            strokeWeight: 2,
                            scale: 0.9,
                            anchor: new window.google.maps.Point(0, 0),
                            labelOrigin: new window.google.maps.Point(0, -23)
                          }
                          ,
                          map: mapInstance,
                          opacity: isSelectedRoute ? 1.0 : 0.2
                      });

                      // Create InfoWindow for the destinationMarker
                      const destInfoWindow = new window.google.maps.InfoWindow({
                          content: `<div>${orderedArray[endIndex].address}</div>`
                      });

                      // Add click event listener to the destinationMarker
                      destinationMarker.addListener('click', () => {
                          destInfoWindow.open(mapInstance, destinationMarker);
                      });

                      legMarkers.push(destinationMarker);

                      const directionsRenderer = new window.google.maps.DirectionsRenderer({
                          map: mapInstance,
                          suppressMarkers: true, // This is important! It will hide default markers.
                          directions: result,
                          polylineOptions: {
                              strokeColor: route.driverInfo.colorCode,
                              strokeOpacity: isSelectedRoute ? 1 : 0.2,  // Make other routes less prominent
                              strokeWeight: 5
                          }
                      });

                      // Add the newly created renderers and markers to the useRef collections
                      allRenderers.current.push(directionsRenderer);
                      legMarkers.forEach(marker => allMarkers.current.push(marker));

                      fetchSegmentedRoutes(endIndex);
                  } else {
                      console.error(`Error fetching directions for segment starting at ${startIndex}: ${status}`);
                  }
              });
          }

          // (DOES NOT PROPERLY WORK) If a route is selected, zoom into the selected route
          if (isSelectedRoute && orderedArray.length > 0) {
              mapInstance.setCenter(orderedArray[0].coordinates);
              mapInstance.setZoom(12);  // Adjust zoom level as needed
          }

          // Start processing the segments.
          fetchSegmentedRoutes();
      });
  } else {
      // Clear existing renderers and markers
      allRenderers.current.forEach(renderer => renderer.setMap(null));
      allMarkers.current.forEach(marker => marker.setMap(null));
      allRenderers.current = [];
      allMarkers.current = [];

      // Clear directions
      setDirections(null);

      // should trigger other useEffect to add markers
      let temp = removeRouteLines + 1
      setRemoveRouteLines(temp)
  }
}, [props.driverRoutes, props.clickedDriver]);  // Ensure the useEffect reacts to changes in clickedDriver


  return (
    <GoogleMap
      onLoad={map => setMapInstance(map)}
      zoom={mapZoom}
      center={mapCenter}
      options={{ styles: mapStylesTeams }}
      mapContainerStyle={{ width: "100%", height: "100%" }}
    >
      {markers.map(location => (
        location.mark
      ))}
      <DirectionsRenderer
        directions={directions}
      />
      {selectedLocation && (
        <InfoWindow
          onCloseClick={() => {
            setSelectedLocation(null);
          }}
          position={{
            lat: selectedLocation.coordinates[0],
            lng: selectedLocation.coordinates[1]
          }}
        >
          <div>
            <p className="infowindow-text">{selectedLocation.name}</p>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
}

function MapInterface(props) {
    const [isGoogleMapsReady, setIsGoogleMapsReady] = useState(false);

    useEffect(() => {
        if (window.google && window.google.maps) {
            // Google Maps API is loaded and ready to use
            setIsGoogleMapsReady(true);
        } else {
            const handleLoad = () => {
                setIsGoogleMapsReady(true);
            };
            window.addEventListener("load", handleLoad);

            return () => {
                window.removeEventListener("load", handleLoad);
            };
        }
    }, []);

    return (
        <div className="map pt-2">
            {isGoogleMapsReady && <Map {...props} />}
        </div>
    );
}

export default MapInterface;